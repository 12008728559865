import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Brewery from "../components/brewery";

export default function StateList({ data }) {
  const stateData = data.allMetrosJson.edges[0].node;

  const title = `Best Breweries - ${stateData.meta.city}, ${stateData.meta.st} ${stateData.meta.country} | Finding Beer`;
  return (
    <Layout>
      <SEO
        title={title}
        keywords={[
          `breweries`,
          `finding.beer`,
          stateData.meta.city,
          stateData.meta.st,
          `beer`,
          `top breweries`,
        ]}
      />

      <section className="bg-white border-b py-8 mt-10 px-2">
        
        <div className="container max-w-5xl mx-auto m-8 content-center">
        
          <ul className="list-decimal text-sm text-center">
            <li className="inline-block mr-2"><a href="/best-breweries/" className="underline">Brewery Lists</a></li> /
            <li className="inline-block mr-2 ml-2"><a href="/best-breweries/list/metros/" className="underline">Top US Metros</a></li> /
            <li className="inline-block ml-2">{stateData.meta.city}, {stateData.meta.st}</li>
          </ul>
        
          <h2 className="w-full mt-8 font-bold leading-tight text-center text-gray-800">
            <span className="block text-5xl my-2">Best Breweries</span>
            <span className="block text-3xl my-2">{stateData.meta.city}, {stateData.meta.st}, {stateData.meta.country}</span>
          </h2>
          <img
            class="rounded-lg w-full"
            src={`https://cdn.finding.beer/static/images/metro-us/${stateData.meta.code}.jpg`}
            alt={`Image of ${stateData.meta.city}, ${stateData.meta.st}`}
          />
          <div className="flex mx-auto my-8 content-center w-80 text-xl">
            <div className="flex fb-quality-6 mx-4 basis-1/5 border-t-8 justify-center pt-1" title="Reserved for a small list of extrodinary breweries that you should seek out.">😍</div>
            <div className="flex fb-quality-5 mx-4 basis-1/5 border-t-8 justify-center pt-1" title="Fantastic breweries that consistently produce world class beer.">😄</div>
            <div className="flex fb-quality-4 mx-4 basis-1/5 border-t-8 justify-center pt-1" title="Breweries that make darn good beer but not always the ones that you'll remember.">🙂</div>
            <div className="flex fb-quality-3 mx-4 basis-1/5 border-t-8 justify-center pt-1" title="Breweries that do not necessarily make bad beer but ones that are fairly ordinary.">🤨 </div>
            <div className="flex fb-quality-1 mx-4 basis-1/5 border-t-8 justify-center pt-1" title="Breweries that most people who enjoy craft beer generally avoid.">🙁</div>
          </div>

          
          {stateData.data.slice(0, 10).map((data, idx) => (
            <>
              <Brewery key={data.rbId} data={data} idx={idx} />
            </>
          ))}
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($name: String!) {
    allMetrosJson(filter: { meta: { code: { eq: $name } } }) {
      edges {
        node {
          meta {
            country
            st
            city
            code
          }
          data {
            rbId
            utId
            utBreweryName
            utBreweryDescription
            utBreweryLogo
            fbQuality
            fbScore
            rbCity
            rbCountry
            rbState
            fbRank
            utRatings
          }
        }
      }
    }
  }
`;
